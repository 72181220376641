import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../common/styles/colors";
import {
  commonParagraph,
  commonParagraph14px,
  commonParagraph12px,
  commonHeaderThree
} from "../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    projectViewWrapper: {
      margin: "0 auto",
      maxWidth: "1040px",
      paddingTop: theme.spacing(5),
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    headerWrapper: {
      display: "flex",
      flexDirection: "row",
    },
    heading: {
      fontSize: "44px",
      lineHeight: "47px",
      fontFamily: "VistaSansReg",
      color: colors.primaryColor,
      fontWeight: 700,
      marginBottom: theme.spacing(4),
      wordBreak: "break-word"
    },
    content: {
      color: colors.darkerGreyColor,
      ...commonParagraph,
    },
    editName: {
      marginLeft: "25px",
      cursor: 'pointer',
    },
    inputLabel: {
      color: colors.darkerGreyColor,
      ...commonParagraph,
      paddingBottom: "8px",
      "& .MuiInputLabel-asterisk": {
        color: "red",
      },
    },
    inputName: {
      minWidth: "440px",
      height: "48px",
      padding: "1px 16px",
      borderRadius: "24px",
      border: `1px ${colors.lightGreyColor}`,
      backgroundColor: colors.veryLightGreyColor,
      "& input": {
        fontSize: "14px",
        color: colors.primaryColor,
        fontFamily: "humanistBD",
        textAlign: "center",
      },
      cursor: "default",
    },
    actionsWrapper: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      display: "flex",
      justifyContent: "space-between",
      //maxWidth: "650px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "space-around",
        flexDirection: "column",
        display: "flex",
      },
      "& button": {
        marginRight: "20px",
         [theme.breakpoints.down("md")]: {
          marginRight: "10px",
        },
        [theme.breakpoints.down("sm")]: {
          marginRight: "2px",
        }
      },
      "& div.searchBox": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginLeft: "25px",
        borderRadius: "24px",
        padding: "0 24px",
        width: "380px",
        backgroundColor: colors.secondaryColor,
        "& button": {
          marginRight: "0",
        },
        [theme.breakpoints.down("md")]: {
          width: "100%",
          margin: "20px 0"
        },
      },
    },
    root: {
      "& span": {
        fontSize: "14px",
        fontFamily: "humanistRM",
        color: colors.primaryColor,
      },
    },
    infoItem: {
      maxWidth: "1040px",
      borderBottom: `0px solid ${colors.primaryColor}`,
      padding: "7px 0",
      "& img": {
        left: 0,
        paddingLeft: "56px",
        position: "relative",
      },
      backgroundColor: colors.secondaryColor,
    },
    nestedInfo: {
      paddingLeft: theme.spacing(5.8),
      fontFamily: "humanistBD",
      fontSize: "14px",
    },
    listItemText: {
      flex: "none",
      width: "27%",
      "& span": {
        ...commonParagraph14px,
        fontWeight: 700,
      },
    },
    titleHeader: {
      "& span": {
        ...commonParagraph14px,
        fontWeight: 700,
      },
    },
    iconAlign: {
      marginRight: "18px",
    },
    checkAlign: {
      marginRight: "37px",
    },
    alignContent: {
      width: "90%",
      display: "flex",
      justifyContent: "space-around",
    },
    listContent: {
      flex: "none",
      width: "28%",
      position: "relative",
      "& span": {
        ...commonParagraph14px,
        position: "absolute",
        top: "50%",
        height: "16px",
        marginTop: "-8px",
        paddingRight: "40px"
      },
      cursor: "default",
      wordBreak: "break-word",
    },
    typo: {
      ...commonParagraph14px,
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "column",
      "& button": {
        marginTop: "32px",
        width: "100%",
        height: "48px",
      },
      width: "364px",
      justifyContent: "space-around",
    },
    verticalInputSpacing: {
      paddingLeft: "8px",
      borderLeft: `4px solid transparent`,
      marginLeft: `-12px`,
    },
    errorBorder: {
      borderLeft: `4px solid ${colors.errorRedColor}`,
    },
    error: {
      color: colors.errorRedColor,
      fontSize: "12px",
      lineHeight: "16px",
      marginTop: "8px",
    },
    modalCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "402px",
      padding: "104px 32px 0",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    modalExportCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "488px",
      paddingTop: theme.spacing(13),
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    modalDeleteCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "402px",
      paddingTop: theme.spacing(13),
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      }
    },
    projectDetails: {
      cursor: "default",
      "& span":{
        fontWeight: "bold",
        paddingRight: "10px",
      },
      ...commonParagraph14px,
    },
    projectDetailsFW: {
      cursor: "default",
      flexDirection: "column",
      flexBasis: "210px",
      padding: "0",
      "& p": {
        fontFamily: "humanistRM",
        fontSize: "14px",
      },
      "& span":{
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    projectDetailsTable: {
      width:"100%",
      [theme.breakpoints.down("sm")]: {
      overflowX:"scroll"
      }
    },
    projectDetailsContainer: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "536px",
      flexWrap: "nowrap",
      maxHeight: "700px",
      overflowY: "auto",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      // mixBlendMode: "multiply",
      alignItems: "center",
      padding: "35px 40px 60px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
      },
    },
  })
);

export default useStyles;
